.header {
    background-color: #FFD5A4;
    width: 92.5%;
    height: 80px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #808080;
    z-index: 20;
}

.header__logo {
    height: 60px;
    margin-top: 10px;
}

.header__text {
    margin-top: 15px;
    margin-left: 25px;
    font-family: Algerian;
}