.sd__topbar{
    margin-top: 5px;
}
.sd__options {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top: 1px solid grey;
    position: fixed;
    bottom: 0;
    height: 78vh;
    width: 100%;
    background: linear-gradient(#FFF7E9, #FFD284);
}

.sd__option__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 7%;
    padding-right: 7%;
}

.sd__option {
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border: 1px solid grey;
    border-radius: 50%;
    display: inline-block;
}

.sd__option__text {
    margin-top: 0px;
    margin-left: 20px;
}

.sd__toptext {
    margin-top: 0px;
    max-width: 40ch;
    text-align: center;
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  }
  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }
/*   
  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }
  
  span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  } */