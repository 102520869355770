.it__issue-tracker-container {
  padding: 20px;
}

.it__welcome-container {
  margin-bottom: 20px;
  margin-left: 20px;
}

.it__raise-issue-button {
  margin-top: 10px;
}

.it__search-bar {
  margin-bottom: 20px;
}

.it__raised-issue {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.it__modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -47%);
  background-color: white;
  padding: 20px;
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 80%;
  width: 80%;
}

.it__modal-title {
  margin-bottom: 20px;
}

.it__category-dropdown,
.it__issue-description-input {
  width: 100%;
  margin-bottom: 20px;
}

.it__submit-issue-button {
  margin-top: 10px;
  width: 90%;
}

/* Additional styles for Accordion */
.it__raised-issue .MuiAccordionSummary-content {
  align-items: center;
}

.it__raised-issue .MuiAccordionDetails {
  flex-direction: column;
}
