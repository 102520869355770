.welcome__topdiv{
    height: 66vh;
    background: linear-gradient(#FFF7E9, #FFD284);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom: 1px solid grey;
}

.welcome__downdiv{
    width: 80vw;
    margin-top: 20px;
}
.welcome__separate{
    width: 100%; 
    height: 15px; 
    border-bottom: 1px solid black; 
    text-align: center;
}
.welcome__span{
    font-size: 21px; 
    background-color: #ffffff; 
    padding: 0 40px;
}