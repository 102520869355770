.home__topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 10px;
}

.home__options {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top: 1px solid grey;
    position: fixed;
    bottom: 0;
    height: 82vh;
    width: 100%;
    background: linear-gradient(#FFF7E9, #FFD284);
}

.home__option__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 4%;
    padding-right: 4%;
}

.home__option {
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border: 1px solid grey;
    border-radius: 10px;
    display: inline-block;
}

.home__option__text {
    margin-top: 0px;
    margin-left: 20px;
}

.home__toptext {
    max-width: 40ch;
    text-align: center;
  }
